import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import getLanguageIcon from '../../helpers/getLanguageIcon';

const LanguageToggle = ({
  classes,
  anchorEl,
  handleClose,
  handleClick,
  changeLanguage,
  lng,
  menuItems,
}) => {
  if (!menuItems.length) {
    return null;
  }

  return (
    <>
      <Avatar
        alt="lng"
        src={getLanguageIcon(lng)}
        onClick={handleClick}
        className={classes.languageButton}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {menuItems.map((item, key) => (
          <MenuItem
            key={key}
            onClick={() => changeLanguage(item.language)}
            selected={lng === item.language}>
            <Avatar
              alt="lng"
              src={item.icon}
              onClick={handleClick}
              className={classes.languageButton}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LanguageToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
};

export default withStyles(styles)(LanguageToggle);
