import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import DocumentTitle from 'react-document-title';
import { ErrorOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import config from 'config';

import Layout from '../../../../components/LayoutNew';

import TextInput from 'modules/common/TextInput';
import Button from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';
import styles from './styles';
import { required, password, stringLengthMax128 } from 'utils/validation/fieldValidationRules';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import Spinner from 'modules/common/Spinner';

const ResetPassword = ({ classes, handleSubmit, error = null, isPending }) => {
  const { t } = useTranslation('auth');
  const { t: tCommon } = useTranslation('common');
  const { CURRENT_HUB_LABEL, LOGO, IS_CLIM, SIGNIN_DISCLAIMER } = config;

  return (
    <Layout>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle title={t('resetPassword.documentTitle', { company: CURRENT_HUB_LABEL })} />
        <div className={classes.formWrapper}>
          <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
            <img
              src={LOGO.blue}
              className={classNames(classes.logo, { [classes.climCustomLogo]: IS_CLIM })}
              alt="black-logo"
            />
          </CustomLink>
          <form className={classes.form} onSubmit={handleSubmit}>
            <section className={classes.wrapper}>
              {error && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  &nbsp;
                  {getTranslation(t, error)}
                </span>
              )}
              <h2 className={classes.title}>{t('resetPassword.title')}</h2>
              <Field
                name="password"
                label={t('common:labels.newPassword')}
                placeholder={t('common:labels.newPassword')}
                isConfidential
                tipText={tCommon('errors.form.password3of4Types')}
                component={TextInput}
                className={classes.textField}
                validate={[required, password, stringLengthMax128]}
              />
              <Field
                name="confirmPassword"
                label={t('common:labels.confirmPassword')}
                placeholder={t('common:labels.confirmPassword')}
                isConfidential
                component={TextInput}
                className={classNames(classes.textField, classes.confirmPassword)}
                validate={[required, password, stringLengthMax128]}
              />
              {isPending ? (
                <div className={classes.loader}>
                  <Spinner />
                </div>
              ) : (
                <Button
                  isPending={isPending}
                  fullWidth
                  color="primary"
                  size="large"
                  label={t('common:buttons.saveChanges')}
                  type="submit"
                  className={classNames(classes.submitButton, {
                    [classes.climCustomButton]: IS_CLIM,
                  })}
                />
              )}

              <section className={classes.customLinkWrapper}>
                <p className={classes.customLinkText}>{t('resetPassword.rememberOldPassword')}</p>
                <CustomLink
                  className={classNames({ [classes.climCustomLink]: IS_CLIM })}
                  to="/auth/sign-in">
                  {t('common:buttons.signIn')}
                </CustomLink>
              </section>
            </section>
          </form>
        </div>
      </div>
    </Layout>
  );
};

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    formError: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    customLinkWrapper: PropTypes.string.isRequired,
    customLinkText: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
  }).isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default compose(reduxForm(), withStyles(styles))(ResetPassword);
