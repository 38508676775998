import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../components/LayoutNew';
import Button from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';
import styles from './styles';
import globalPrimeLogo from 'static/global-prime_logo.svg';
import logoFMx from 'static/logoFMx.svg';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import Spinner from '../../../../../common/Spinner';

const Migration = ({ classes, handleSubmit, isLoading, hub }) => {
  const { t } = useTranslation('');

  return (
    <Layout>
      <form className={classes.form} onSubmit={handleSubmit}>
        <CustomLink to={getStaticSiteUrl()}>
          <img
            src={process.env.REACT_APP_ENV === 'productionX' ? logoFMx : globalPrimeLogo}
            alt="black-logo"
          />
        </CustomLink>
        <h3 className={classes.title}>{t('migrateMyAccount:confirmTitle')}</h3>
        <textarea
          readOnly={true}
          rows={6}
          className={classes.legal}
          value={t(`migrateMyAccount:legal.${hub}`)}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <Button
            fullWidth
            className={classes.confirmButton}
            color="primary"
            size="large"
            label={t('common:buttons.agree')}
            type="submit"
            disabled={isLoading}
          />
        )}
      </form>
    </Layout>
  );
};

Migration.propTypes = {};

export default compose(reduxForm(), withStyles(styles))(Migration);
