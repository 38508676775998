import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { useTranslation } from 'react-i18next';
import { lettersWithSymbolsAndNumbers } from 'utils/normalizations';
import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';
import { Field } from 'redux-form';
import TPTextField from 'components/TP-UI/TPTextField';
import {
  checkNonLatin,
  required,
  stringLengthMax6,
  stringLengthMin6,
} from 'utils/validation/fieldValidationRules';
import PropTypes from 'prop-types';
import FormError from 'modules/common/FormError';

const EnableMfaForm = ({ qrCode, error, submitFailed }) => {
  const { t } = useTranslation('auth');

  return (
    <TPGrid container spacing={3} direction="column">
      {submitFailed && error && (
        <TPGrid item xs={12}>
          <FormError error={error} />
        </TPGrid>
      )}
      <TPGrid item container spacing={2} direction="column">
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step1')}</TPTypography>
        </TPGrid>
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step2')}</TPTypography>
        </TPGrid>
        {qrCode && (
          <TPGrid item>
            <TPImage src={qrCode} alt="QR code" />
          </TPGrid>
        )}
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step3')}</TPTypography>
        </TPGrid>
        <TPGrid item xs={12}>
          <Field
            component={TPTextField}
            fullWidth
            required
            name="code"
            label={t('common:labels.code')}
            placeholder={t('mfa.verificationForm.inputPlaceholder')}
            type="text"
            validate={[checkNonLatin, required, stringLengthMax6, stringLengthMin6]}
            normalize={lettersWithSymbolsAndNumbers}
          />
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

EnableMfaForm.propTypes = {
  qrCode: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitFailed: PropTypes.bool,
};

export default EnableMfaForm;
