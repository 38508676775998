import { handleActions } from 'redux-actions';

import { getVerificationChecklistQuestionsSuccess, triggerChecklistState } from '../actions';
import { VERIFICATION_CHECK_QUESTION_TYPES } from '../constants';

const defaultState = {
  userChecklist: [],
  accountChecklist: [],
  isChecklistOpen: false,
};

export default handleActions(
  {
    [getVerificationChecklistQuestionsSuccess](state, action) {
      return {
        ...state,
        ...(action.payload.type === VERIFICATION_CHECK_QUESTION_TYPES.NEW_ACCOUNT
          ? { userChecklist: action.response.data }
          : { accountChecklist: action.response.data }),
      };
    },
    [triggerChecklistState](state) {
      return {
        ...state,
        isChecklistOpen: !state.isChecklistOpen,
      };
    },
  },
  defaultState,
);
