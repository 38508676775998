import React from 'react';
import PropTypes from 'prop-types';
import AccordionSummary from 'material-latest/AccordionSummary';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPAccordionSummary = ({
  titleIcon: TitleIcon,
  title,
  variant = 'outlined',
  titleVariant = 'body1',
  boldTitle,
  className,
}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreOutlinedIcon />}
      sx={[styles.root, variant === 'elevation' && styles.elevation, className]}>
      <TPGrid container spacing={2} alignItems="center">
        {TitleIcon && (
          <TPGrid item>
            <TitleIcon fontSize={SIZES.MEDIUM} sx={styles.titleIcon} />
          </TPGrid>
        )}
        <TPGrid item>
          <TPTypography variant={titleVariant} bold={boldTitle}>
            {title}
          </TPTypography>
        </TPGrid>
      </TPGrid>
    </AccordionSummary>
  );
};

TPAccordionSummary.propTypes = {
  titleIcon: PropTypes.elementType,
  title: PropTypes.string,
  titleVariant: TPTypography.propTypes.variant,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
};

export default TPAccordionSummary;
