import isMobile from 'utils/isMobile';
import { Routes } from '../../../constants/routeConstants';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BarChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';

import toolsIcon from 'static/ico_tools.svg';
import { default as accountsIcon, default as migrateMyAccountIcon } from 'static/ico_accounts.svg';
import verificationIcon from 'static/ico_id_verification.svg';
import userManagementIcon from 'static/ico_user_management.svg';
import mailManageIcon from 'static/mail_manage.svg';
import iconAccounts from 'static/menu/accounts.svg';
import iconDownloads from 'static/menu/downloads.svg';
import iconPayments from 'static/menu/payments.svg';
import iconPrimePlus from 'static/menu/primePlus.svg';
import iconProfile from 'static/menu/profile.svg';
import iconReduceFees from 'static/menu/reduceFees.svg';
import iconSponsoredVps from 'static/menu/sponsoredVps.svg';
import iconTradingCalculators from 'static/menu/tradingCalculators.svg';
import { Roles } from '../../../constants/userRoles';

import assignmentLate from 'static/menu/assignment_late.svg';
import gpDbad from 'static/menu/fmd_bad.svg';
import emojiHourglass from 'static/menu/hourglass_empty.svg';
import newsStand from 'static/menu/news_stand.svg';
import notListedLocation from 'static/menu/not_listed_location.svg';
import notificationsActive from 'static/menu/notifications_active.svg';
import swapCalls from 'static/menu/swap_calls.svg';
import tune from 'static/menu/tune.svg';
import verifiedUser from 'static/menu/verified_user.svg';
import { MENU_ITEM_ID } from './index';

export const MENU_ITEMS = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    iconComponent: toolsIcon,
    path: Routes.DASHBOARD,
    access: [Roles.ADMIN],
    mainMenuItem: true,
  },
  {
    id: 'id_verification',
    label: 'ID Verification',
    iconComponent: verificationIcon,
    path: Routes.VERIFICATION_MANAGEMENT,
    mainMenuItem: true,
  },
  {
    id: 'payment_management',
    label: 'Payment Management',
    iconComponent: iconPayments,
    path: Routes.PAYMENT_MANAGEMENT,
    mainMenuItem: true,
  },
  {
    id: 'email_management',
    label: 'Email Management',
    iconComponent: mailManageIcon,
    path: Routes.EMAIL_MANAGEMENT,
    mainMenuItem: false,
  },
  {
    id: 'user_management',
    label: 'User Management',
    iconComponent: userManagementIcon,
    path: Routes.USER_MANAGEMENT,
    mainMenuItem: false,
  },
  {
    id: 'operations',
    label: 'Operations',
    iconComponent: accountsIcon,
    path: Routes.OPERATIONS,
    mainMenuItem: false,
  },
  {
    id: 'settingsMenu',
    label: 'Settings',
    iconComponent: iconProfile,
    mainMenuItem: false,
    items: [
      {
        id: 'migration_requests',
        label: 'Migration requests',
        iconComponent: swapCalls,
        path: Routes.MIGRATION_REQUESTS,
      },
      {
        id: 'security',
        label: 'Security',
        iconComponent: verifiedUser,
        path: Routes.SECURITY,
      },
      {
        id: 'pageWarning',
        label: 'Page Warning',
        iconComponent: assignmentLate,
        path: Routes.PAGE_WARNING,
      },
      {
        id: 'notifications',
        label: 'Notifications',
        iconComponent: notificationsActive,
        path: Routes.NOTIFICATIONS,
      },
      {
        id: 'riskGroup',
        label: 'Risk Group',
        iconComponent: gpDbad,
        path: Routes.RISK_GROUP,
      },
      {
        id: 'paymentQueue',
        label: 'Queue',
        iconComponent: emojiHourglass,
        path: Routes.PAYMENT_QUEUE,
      },
      {
        id: 'questions',
        label: 'Custom Fields',
        iconComponent: notListedLocation,
        path: Routes.CUSTOM_FIELDS,
      },
      {
        id: 'settings',
        label: 'Settings',
        iconComponent: tune,
        path: Routes.SETTINGS,
      },
    ],
  },
  {
    id: 'accounts',
    label: { key: 'menu:category.accounts' },
    iconComponent: iconAccounts,
    path: Routes.MY_ACCOUNTS,
    mainMenuItem: true,
  },
  {
    id: 'payments',
    label: { key: 'menu:category.payments' },
    iconComponent: iconPayments,
    path: Routes.PAYMENTS,
    mainMenuItem: true,
  },
  {
    id: 'vps',
    label: { key: 'menu:category.vps' },
    iconComponent: iconSponsoredVps,
    path: Routes.VPS,
    mainMenuItem: false,
  },
  {
    id: 'tradingCalculators',
    label: {
      key: isMobile()
        ? 'menu:category.mobileTradingCalculatrs'
        : 'menu:category.tradingCalculators',
    },
    iconComponent: iconTradingCalculators,
    path: Routes.TRADING_CALCULATORS,
    mainMenuItem: true,
  },
  {
    id: 'downloads',
    label: { key: 'menu:category.downloads' },
    iconComponent: iconDownloads,
    path: Routes.PLATFORMS,
    mainMenuItem: false,
  },
  {
    id: 'tradeIdeasMenu',
    label: 'Trade Ideas',
    iconComponent: AutoGraphIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'technical_analysis',
        label: 'Technical Analysis',
        iconComponent: DashboardOutlinedIcon,
        path: Routes.TRADE_IDEAS_TECHNICAL_ANALYSIS,
      },
      {
        id: 'performance_statistics',
        label: 'Performance Statistics',
        iconComponent: PieChartOutlinedIcon,
        path: Routes.TRADE_IDEAS_PERFORMANCE_STATISTICS,
      },
      {
        id: 'volatility_analysis',
        label: 'Volatility Analysis',
        iconComponent: BarChartIcon,
        path: Routes.TRADE_IDEAS_VOLATILITY_ANALYSIS,
      },
      {
        id: 'market_report',
        label: 'Market Report',
        iconComponent: ListAltIcon,
        path: Routes.TRADE_IDEAS_MARKET_REPORT,
      },
    ],
  },
  {
    id: 'learningsMenu',
    label: 'Learnings',
    iconComponent: RocketLaunchOutlinedIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'newsSentiment',
        label: 'News Sentiment',
        iconComponent: newsStand,
        path: Routes.LEARNINGS_NEWS_SENTIMENT,
      },
      {
        id: 'education',
        label: 'Education',
        iconComponent: DashboardOutlinedIcon,
        path: Routes.LEARNINGS_NEWS_EDUCATION,
      },
    ],
  },
  {
    id: 'messagingAndAllerts',
    label: 'Messaging & Alerts',
    iconComponent: NotificationsOutlinedIcon,
    path: Routes.MESSAGING_AND_ALERTS,
    mainMenuItem: false,
  },
  {
    id: 'downloadAutochartist',
    label: 'Download Autochartist',
    iconComponent: CandlestickChartOutlinedIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'tradingTools_downloads',
        label: 'MT4/5 Scanner',
        iconComponent: ExtensionOutlinedIcon,
        path: Routes.MT4_DOWNLOADS,
      },
      {
        id: 'tradingTools_downloads_mobile',
        label: 'Autochartist Mobile App',
        iconComponent: InstallMobileOutlinedIcon,
        path: Routes.MT4_DOWNLOADS_MOBILE,
      },
    ],
  },
  {
    id: 'reduceFees',
    label: { key: 'menu:category.reduceFees' },
    iconComponent: iconReduceFees,
    path: Routes.REDUCE_FEES,
    mainMenuItem: false,
  },
  {
    id: 'primePlus',
    label: 'GP Copy',
    iconComponent: iconPrimePlus,
    mainMenuItem: false,
    items: [
      {
        id: 'primePlusAbout',
        label: `About`,
        path: Routes.PRIME_PLUS_ABOUT,
        iconComponent: ContactSupportOutlinedIcon,
      },
      {
        id: 'primePlusMyInvitations',
        label: `My Invitations`,
        path: Routes.PRIME_PLUS_INVITATIONS,
        iconComponent: ConfirmationNumberOutlinedIcon,
      },
      {
        id: 'primePlusTrades',
        label: 'Trades',
        path: Routes.PRIME_PLUS_TRADES,
        iconComponent: TimelineIcon,
      },
      {
        id: 'primePlusMyFollowers',
        label: 'My Followers',
        path: Routes.PRIME_PLUS_MY_FOLLOWERS,
        iconComponent: GroupAddOutlinedIcon,
      },
      {
        id: 'primePlusMasterAccounts',
        label: 'My Active Copiers',
        path: Routes.PRIME_PLUS_MASTER_ACCOUNTS,
        iconComponent: SupervisorAccountOutlinedIcon,
      },
      {
        id: 'primePlusJoinWaitlist',
        label: 'Leaderboard',
        path: Routes.PRIME_PLUS_MY_FOLLOWERS_WAITLIST,
        iconComponent: ListAltOutlinedIcon,
      },
    ],
  },
  {
    id: 'upgradeToPro',
    label: { key: 'menu:category.upgradeToPro' },
    iconComponent: migrateMyAccountIcon,
    path: Routes.UPGRADE_TO_PRO_V_2,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.CRYPTO_WAITLIST_EN,
    label: { key: 'menu:category.cryptoWaitlistEn' },
    iconComponent: AlarmIcon,
    path: Routes.CRYPTO_WAITLIST,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.CRYPTO_WAITLIST,
    label: { key: 'menu:category.cryptoWaitlist' },
    iconComponent: MonetizationOnOutlinedIcon,
    path: Routes.DAY1_EXCHANGE,
    mainMenuItem: false,
  },
];
