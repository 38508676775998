import Cookies from 'js-cookie';
import { LANG_COOKIE_NAME, DEFAULT_LANG } from 'constants/index';

import enMenu from './translations/en/menu.json';
import thMenu from './translations/th/menu.json';
import chMenu from './translations/ch/menu.json';
import chmMenu from './translations/chm/menu.json';
import idMenu from './translations/id/menu.json';
import jpMenu from './translations/jp/menu.json';

import enCommon from './translations/en/common.json';
import thCommon from './translations/th/common.json';
import chCommon from './translations/ch/common.json';
import chmCommon from './translations/chm/common.json';
import idCommon from './translations/id/common.json';
import jpCommon from './translations/jp/common.json';

import enProfile from './translations/en/profile.json';
import thProfile from './translations/th/profile.json';
import chProfile from './translations/ch/profile.json';
import chmProfile from './translations/chm/profile.json';
import idProfile from './translations/id/profile.json';
import jpProfile from './translations/jp/profile.json';

import enContactUs from './translations/en/contactUs.json';
import thContactUs from './translations/th/contactUs.json';
import chContactUs from './translations/ch/contactUs.json';
import chmContactUs from './translations/chm/contactUs.json';
import idContactUs from './translations/id/contactUs.json';
import jpContactUs from './translations/jp/contactUs.json';

import enTools from './translations/en/tools.json';
import thTools from './translations/th/tools.json';
import chTools from './translations/ch/tools.json';
import chmTools from './translations/chm/tools.json';
import idTools from './translations/id/tools.json';
import jpTools from './translations/jp/tools.json';

import enAccounts from './translations/en/accounts.json';
import thAccounts from './translations/th/accounts.json';
import chAccounts from './translations/ch/accounts.json';
import chmAccounts from './translations/chm/accounts.json';
import idAccounts from './translations/id/accounts.json';
import jpAccounts from './translations/jp/accounts.json';

import enPayments from './translations/en/payments.json';
import thPayments from './translations/th/payments.json';
import chPayments from './translations/ch/payments.json';
import chmPayments from './translations/chm/payments.json';
import idPayments from './translations/id/payments.json';
import jpPayments from './translations/jp/payments.json';

import enAuth from './translations/en/auth.json';
import thAuth from './translations/th/auth.json';
import chAuth from './translations/ch/auth.json';
import chmAuth from './translations/chm/auth.json';
import idAuth from './translations/id/auth.json';
import jpAuth from './translations/jp/auth.json';

import enBackErrors from './translations/en/errorList.json';
import thBackErrors from './translations/th/errorList.json';
import chBackErrors from './translations/ch/errorList.json';
import chmBackErrors from './translations/chm/errorList.json';
import idBackErrors from './translations/id/errorList.json';
import jpBackErrors from './translations/jp/errorList.json';

import enNotFound from './translations/en/notFound.json';
import thNotFound from './translations/th/notFound.json';
import chNotFound from './translations/ch/notFound.json';
import chmNotFound from './translations/chm/notFound.json';
import idNotFound from './translations/id/notFound.json';
import jpNotFound from './translations/jp/notFound.json';

import enEconomicCalendar from './translations/en/economicCalendar.json';
import thEconomicCalendar from './translations/th/economicCalendar.json';
import chEconomicCalendar from './translations/ch/economicCalendar.json';
// import chmEconomicCalendar from './translations/chm/economicCalendar.json';
import idEconomicCalendar from './translations/id/economicCalendar.json';
import jpEconomicCalendar from './translations/jp/economicCalendar.json';

import enTradingCalculators from './translations/en/tradingCalculators.json';
import thTradingCalculators from './translations/th/tradingCalculators.json';
import chTradingCalculators from './translations/ch/tradingCalculators.json';
import chmTradingCalculators from './translations/chm/tradingCalculators.json';
import idTradingCalculators from './translations/id/tradingCalculators.json';
import jpTradingCalculators from './translations/jp/tradingCalculators.json';

import enReduceFees from './translations/en/reduceFees.json';
import thReduceFees from './translations/th/reduceFees.json';
import chReduceFees from './translations/ch/reduceFees.json';
import chmReduceFees from './translations/chm/reduceFees.json';
import idReduceFees from './translations/id/reduceFees.json';
import jpReduceFees from './translations/jp/reduceFees.json';

import enMigrateMyAccount from './translations/en/migrateMyAccount.json';
import thMigrateMyAccount from './translations/th/migrateMyAccount.json';
import chMigrateMyAccount from './translations/ch/migrateMyAccount.json';
import chmMigrateMyAccount from './translations/chm/migrateMyAccount.json';
import idMigrateMyAccount from './translations/id/migrateMyAccount.json';
import jpMigrateMyAccount from './translations/jp/migrateMyAccount.json';

import enUpgradeToPro from './translations/en/upgradeToPro.json';
import thUpgradeToPro from './translations/th/upgradeToPro.json';
import chUpgradeToPro from './translations/ch/upgradeToPro.json';
// import chmUpgradeToPro from './translations/chm/upgradeToPro.json';
import idUpgradeToPro from './translations/id/upgradeToPro.json';
import jpUpgradeToPro from './translations/jp/upgradeToPro.json';

import enDiscover from './translations/en/discover.json';
import thDiscover from './translations/th/discover.json';
import chDiscover from './translations/ch/discover.json';
// import chmDiscover from './translations/chm/discover.json';
import idDiscover from './translations/id/discover.json';
import jpDiscover from './translations/jp/discover.json';

import enCustomFields from './translations/en/customFields.json';
import thCustomFields from './translations/th/customFields.json';
import chCustomFields from './translations/ch/customFields.json';
import idCustomFields from './translations/id/customFields.json';
import jpCustomFields from './translations/jp/customFields.json';

import enAnalystViews from './translations/en/analystViews.json';
import thAnalystViews from './translations/th/analystViews.json';
import chAnalystViews from './translations/ch/analystViews.json';
// import chmAnalystViews from './translations/chm/analystViews.json';
import idAnalystViews from './translations/id/analystViews.json';
import jpAnalystViews from './translations/jp/analystViews.json';

import enErrorPage from './translations/en/errorPage.json';
import thErrorPage from './translations/th/errorPage.json';
import chErrorPage from './translations/ch/errorPage.json';
import chmErrorPage from './translations/chm/errorPage.json';
import idErrorPage from './translations/id/errorPage.json';
import jpErrorPage from './translations/jp/errorPage.json';

import enTechnicalInsight from './translations/en/technicalInsight.json';
import thTechnicalInsight from './translations/th/technicalInsight.json';
import chTechnicalInsight from './translations/ch/technicalInsight.json';
// import chmTechnicalInsight from './translations/chm/technicalInsight.json';
import idTechnicalInsight from './translations/id/technicalInsight.json';
import jpTechnicalInsight from './translations/jp/technicalInsight.json';

import enOnboarding from './translations/en/onboarding.json';
import thOnboarding from './translations/th/onboarding.json';
import chOnboarding from './translations/ch/onboarding.json';
import chmOnboarding from './translations/chm/onboarding.json';
import idOnboarding from './translations/id/onboarding.json';
import jpOnboarding from './translations/jp/onboarding.json';

import enCityRestrictor from './translations/en/cityRestrictor.json';
import thCityRestrictor from './translations/th/cityRestrictor.json';
import chCityRestrictor from './translations/ch/cityRestrictor.json';
// import chmCityRestrictor from './translations/chm/cityRestrictor.json';
import idCityRestrictor from './translations/id/cityRestrictor.json';
import jpCityRestrictor from './translations/jp/cityRestrictor.json';

import enCountryRestrictor from './translations/en/countryRestrictor.json';
import thCountryRestrictor from './translations/th/countryRestrictor.json';
import chCountryRestrictor from './translations/ch/countryRestrictor.json';
// import chmCountryRestrictor from './translations/chm/countryRestrictor.json';
import idCountryRestrictor from './translations/id/countryRestrictor.json';
import jpCountryRestrictor from './translations/jp/countryRestrictor.json';

import enNotificationCentre from './translations/en/notificationCentre.json';
import chNotificationCentre from './translations/ch/notificationCentre.json';
import thNotificationCentre from './translations/th/notificationCentre.json';
import chmNotificationCentre from './translations/chm/notificationCentre.json';
import idNotificationCentre from './translations/id/notificationCentre.json';
import jpNotificationCentre from './translations/jp/notificationCentre.json';

import enMyFollowers from './translations/en/myFollowers.json';
import chMyFollowers from './translations/ch/myFollowers.json';
import thMyFollowers from './translations/th/myFollowers.json';
import chmMyFollowers from './translations/chm/myFollowers.json';
import idMyFollowers from './translations/id/myFollowers.json';
import jpMyFollowers from './translations/jp/myFollowers.json';

import enMyPerformance from './translations/en/myPerformance.json';
import chMyPerformance from './translations/ch/myPerformance.json';
import thMyPerformance from './translations/th/myPerformance.json';
import chmMyPerformance from './translations/chm/myPerformance.json';
import idMyPerformance from './translations/id/myPerformance.json';
import jpMyPerformance from './translations/jp/myPerformance.json';

import enVPS from './translations/en/vps.json';
import chVPS from './translations/ch/vps.json';
import thVPS from './translations/th/vps.json';
import chmVPS from './translations/chm/vps.json';
import idVPS from './translations/id/vps.json';
import jpVPS from './translations/jp/vps.json';

import enHyperwallet from './translations/en/hyperwallet.json';

import enModals from './translations/en/modals.json';
import chModals from './translations/ch/modals.json';
import chmModals from './translations/chm/modals.json';
import idModals from './translations/id/modals.json';
import jpModals from './translations/jp/modals.json';
import thModals from './translations/th/modals.json';

import enQuiz from './translations/en/quiz.json';
import chQuiz from './translations/ch/quiz.json';
import chmQuiz from './translations/chm/quiz.json';
import idQuiz from './translations/id/quiz.json';
import jpQuiz from './translations/jp/quiz.json';
import thQuiz from './translations/th/quiz.json';

import enEvidenceUploadPortal from './translations/en/evidenceUploadPortal.json';
import chEvidenceUploadPortal from './translations/ch/evidenceUploadPortal.json';
import chmEvidenceUploadPortal from './translations/chm/evidenceUploadPortal.json';
import idEvidenceUploadPortal from './translations/id/evidenceUploadPortal.json';
import jpEvidenceUploadPortal from './translations/jp/evidenceUploadPortal.json';
import thEvidenceUploadPortal from './translations/th/evidenceUploadPortal.json';

import enTradingTools from './translations/en/tradingTools.json';

import enCryptoWaitlist from './translations/en/cryptoWaitlist.json';

// CODE_GETERATOR_NEW_MODULE_PLACEHOLDER

export const resources = {
  en: {
    common: enCommon,
    profile: enProfile,
    contactUs: enContactUs,
    tools: enTools,
    menu: enMenu,
    accounts: enAccounts,
    payments: enPayments,
    auth: enAuth,
    backend: enBackErrors,
    notFoundPage: enNotFound,
    economicCalendar: enEconomicCalendar,
    tradingCalculators: enTradingCalculators,
    reduceFees: enReduceFees,
    migrateMyAccount: enMigrateMyAccount,
    upgradeToPro: enUpgradeToPro,
    discover: enDiscover,
    analystViews: enAnalystViews,
    errorPage: enErrorPage,
    technicalInsight: enTechnicalInsight,
    onboarding: enOnboarding,
    cityRestrictor: enCityRestrictor,
    countryRestrictor: enCountryRestrictor,
    notificationCentre: enNotificationCentre,
    myFollowers: enMyFollowers,
    myPerformance: enMyPerformance,
    vps: enVPS,
    customFields: enCustomFields,
    hyperwallet: enHyperwallet,
    modals: enModals,
    quiz: enQuiz,
    evidenceUploadPortal: enEvidenceUploadPortal,
    tradingTools: enTradingTools,
    cryptoWaitlist: enCryptoWaitlist,
    // CODE_GETERATOR_NEW_EN_LANG_PLACEHOLDER
  },
  th: {
    common: thCommon,
    profile: thProfile,
    contactUs: thContactUs,
    tools: thTools,
    menu: thMenu,
    accounts: thAccounts,
    payments: thPayments,
    auth: thAuth,
    backend: thBackErrors,
    notFoundPage: thNotFound,
    economicCalendar: thEconomicCalendar,
    tradingCalculators: thTradingCalculators,
    reduceFees: thReduceFees,
    migrateMyAccount: thMigrateMyAccount,
    upgradeToPro: thUpgradeToPro,
    discover: thDiscover,
    analystViews: thAnalystViews,
    errorPage: thErrorPage,
    technicalInsight: thTechnicalInsight,
    onboarding: thOnboarding,
    cityRestrictor: thCityRestrictor,
    countryRestrictor: thCountryRestrictor,
    notificationCentre: thNotificationCentre,
    myFollowers: thMyFollowers,
    myPerformance: thMyPerformance,
    vps: thVPS,
    customFields: thCustomFields,
    hyperwallet: enHyperwallet,
    modals: thModals,
    quiz: thQuiz,
    evidenceUploadPortal: thEvidenceUploadPortal,
    // CODE_GETERATOR_NEW_TH_LANG_PLACEHOLDER
  },
  ch: {
    common: chCommon,
    profile: chProfile,
    contactUs: chContactUs,
    tools: chTools,
    menu: chMenu,
    accounts: chAccounts,
    payments: chPayments,
    auth: chAuth,
    backend: chBackErrors,
    notFoundPage: chNotFound,
    economicCalendar: chEconomicCalendar,
    tradingCalculators: chTradingCalculators,
    reduceFees: chReduceFees,
    migrateMyAccount: chMigrateMyAccount,
    upgradeToPro: chUpgradeToPro,
    discover: chDiscover,
    analystViews: chAnalystViews,
    errorPage: chErrorPage,
    technicalInsight: chTechnicalInsight,
    onboarding: chOnboarding,
    cityRestrictor: chCityRestrictor,
    countryRestrictor: chCountryRestrictor,
    notificationCentre: chNotificationCentre,
    myFollowers: chMyFollowers,
    myPerformance: chMyPerformance,
    vps: chVPS,
    customFields: chCustomFields,
    hyperwallet: enHyperwallet,
    modals: chModals,
    quiz: chQuiz,
    evidenceUploadPortal: chEvidenceUploadPortal,
    // CODE_GETERATOR_NEW_CH_LANG_PLACEHOLDER
  },
  chm: {
    common: chmCommon,
    profile: chmProfile,
    contactUs: chmContactUs,
    tools: chmTools,
    menu: chmMenu,
    accounts: chmAccounts,
    payments: chmPayments,
    auth: chmAuth,
    backend: chmBackErrors,
    notFoundPage: chmNotFound,
    // economicCalendar: chmEconomicCalendar,
    tradingCalculators: chmTradingCalculators,
    reduceFees: chmReduceFees,
    migrateMyAccount: chmMigrateMyAccount,
    // upgradeToPro: chmUpgradeToPro,
    // discover: chmDiscover,
    // analystViews: chmAnalystViews,
    errorPage: chmErrorPage,
    // techmnicalInsight: chmTechmnicalInsight,
    onboarding: chmOnboarding,
    // cityRestrictor: chmCityRestrictor,
    // countryRestrictor: chmCountryRestrictor,
    notificationCentre: chmNotificationCentre,
    myFollowers: chmMyFollowers,
    myPerformance: chmMyPerformance,
    vps: chmVPS,
    hyperwallet: enHyperwallet,
    modals: chmModals,
    quiz: chmQuiz,
    evidenceUploadPortal: chmEvidenceUploadPortal,
    // CODE_GETERATOR_NEW_CH_LANG_PLACEHOLDER
  },
  id: {
    common: idCommon,
    profile: idProfile,
    contactUs: idContactUs,
    tools: idTools,
    menu: idMenu,
    accounts: idAccounts,
    payments: idPayments,
    auth: idAuth,
    backend: idBackErrors,
    notFoundPage: idNotFound,
    economicCalendar: idEconomicCalendar,
    tradingCalculators: idTradingCalculators,
    reduceFees: idReduceFees,
    migrateMyAccount: idMigrateMyAccount,
    upgradeToPro: idUpgradeToPro,
    discover: idDiscover,
    analystViews: idAnalystViews,
    errorPage: idErrorPage,
    technicalInsight: idTechnicalInsight,
    onboarding: idOnboarding,
    cityRestrictor: idCityRestrictor,
    countryRestrictor: idCountryRestrictor,
    notificationCentre: idNotificationCentre,
    myFollowers: idMyFollowers,
    myPerformance: idMyPerformance,
    vps: idVPS,
    customFields: idCustomFields,
    modals: idModals,
    quiz: idQuiz,
    evidenceUploadPortal: idEvidenceUploadPortal,
    // CODE_GETERATOR_NEW_EN_LANG_PLACEHOLDER
  },
  jp: {
    common: jpCommon,
    profile: jpProfile,
    contactUs: jpContactUs,
    tools: jpTools,
    menu: jpMenu,
    accounts: jpAccounts,
    payments: jpPayments,
    auth: jpAuth,
    backend: jpBackErrors,
    notFoundPage: jpNotFound,
    economicCalendar: jpEconomicCalendar,
    tradingCalculators: jpTradingCalculators,
    reduceFees: jpReduceFees,
    migrateMyAccount: jpMigrateMyAccount,
    upgradeToPro: jpUpgradeToPro,
    discover: jpDiscover,
    analystViews: jpAnalystViews,
    errorPage: jpErrorPage,
    technicalInsight: jpTechnicalInsight,
    onboarding: jpOnboarding,
    cityRestrictor: jpCityRestrictor,
    countryRestrictor: jpCountryRestrictor,
    notificationCentre: jpNotificationCentre,
    myFollowers: jpMyFollowers,
    myPerformance: jpMyPerformance,
    vps: jpVPS,
    customFields: jpCustomFields,
    modals: jpModals,
    quiz: jpQuiz,
    evidenceUploadPortal: jpEvidenceUploadPortal,
    // CODE_GETERATOR_NEW_EN_LANG_PLACEHOLDER
  },
};

export const namespaces = [
  'common',
  'profile',
  'contactUs',
  'tools',
  'accounts',
  'payments',
  'auth',
  'backend',
  'notFoundPage',
  'errorPage',
];

export const language = Cookies.get(LANG_COOKIE_NAME) || DEFAULT_LANG;

export const fallbackLanguage = DEFAULT_LANG;

export const defaultNamespace = 'common';

export const debug = process.env.REACT_APP_ENV === 'development' ? true : false;
