import config from 'config';
import { languageList } from '../constants';

const { AVAILABLE_LANGUAGES } = config;

export default () => {
  let languages = [];
  languageList.map((item) =>
    AVAILABLE_LANGUAGES.includes(item.language) ? languages.push(item) : null,
  );
  return languages;
};
