const styles = () => ({
  languageButton: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginTop: '4px',
  },
});

export default styles;
