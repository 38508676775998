import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import DocumentTitle from 'react-document-title';
import { ErrorOutline } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import config from 'config';

import Layout from '../../../../components/LayoutNew';

import TextInput from 'modules/common/TextInput';
import Button from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';
import CustomModal from 'modules/common/CustomModal';
import styles from './styles';
import { FORGOT_PASSWORD_MODAL } from '../../constants';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { email, required, checkNonLatin } from 'utils/validation/fieldValidationRules';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import SelectHub from '../SelectHub';
import Spinner from 'modules/common/Spinner';

const ForgotPassword = ({
  classes,
  handleSubmit,
  error = null,
  redirect,
  isPending,
  selectHub,
}) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, IS_CLIM, SIGNIN_DISCLAIMER } = config;

  return (
    <Layout>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle title={t('forgotPassword.documentTitle', { company: CURRENT_HUB_LABEL })} />
        <div className={classes.formWrapper}>
          <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
            <img
              src={LOGO.blue}
              className={classNames(classes.logo, { [classes.climCustomLogo]: IS_CLIM })}
              alt="black-logo"
            />
          </CustomLink>
          <form className={classes.form} onSubmit={handleSubmit}>
            <section className={classes.wrapper}>
              {error && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  &nbsp;
                  {getTranslation(t, error)}
                </span>
              )}
              <CustomLink
                className={classNames(classes.backtoLogin, { [classes.climCustomLink]: IS_CLIM })}
                to="/auth/sign-in">
                <ArrowBack className={classes.backtoLoginIcon} />
                {t('backToLogIn')}
              </CustomLink>
              <h2 className={classes.title}>{'Your password is expired'}</h2>
              <Field
                name="email"
                label={t('common:labels.email')}
                placeholder={t('common:labels.email')}
                type="text"
                component={TextInput}
                className={classes.textField}
                validate={[checkNonLatin, required, email]}
              />
              {isPending ? (
                <div className={classes.loader}>
                  <Spinner />
                </div>
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  label={t('buttons.resetPassword')}
                  type="submit"
                  className={classNames(classes.submitButton, {
                    [classes.climCustomButton]: IS_CLIM,
                  })}
                />
              )}

              <section className={classes.customLinkWrapper}>
                <span className={classes.customLinkText}>{t('notAMemberYet')}</span>
                <CustomLink
                  className={classNames({ [classes.climCustomLink]: IS_CLIM })}
                  to="/auth/sign-up">
                  {t('common:buttons.signUp')}
                </CustomLink>
              </section>
            </section>
            {selectHub ? (
              <SelectHub
                isVisible={selectHub && !isPending}
                onSubmit={handleSubmit}
                isLoading={isPending}
              />
            ) : null}
          </form>
        </div>
        <CustomModal
          className={classes.modal}
          text={t('forgotPassword.modalText')}
          redirect={redirect}
          modalName={FORGOT_PASSWORD_MODAL}
        />
      </div>
    </Layout>
  );
};

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  redirect: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    formError: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    customLinkWrapper: PropTypes.string.isRequired,
    customLinkText: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
  }).isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default compose(reduxForm(), withStyles(styles))(ForgotPassword);
