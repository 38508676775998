import { handleActions } from 'redux-actions';
import { getDay1TransferredUserSuccess, postDay1TransferSuccess } from '../actions';

const defaultState = {
  transferredUser: false,
};

export default handleActions(
  {
    [getDay1TransferredUserSuccess](state, action) {
      return { transferredUser: action.response.data.transferredUser };
    },
    [postDay1TransferSuccess]() {
      return { transferredUser: true };
    },
  },
  defaultState,
);
