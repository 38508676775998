import filterLanguage from '../helpers/filterLanguages';
import en from 'static/en.svg';
import th from 'static/th.svg';
import chm from 'static/chm.svg';
import id from 'static/id.svg';

import { languages as appLanguages } from 'constants/index';

export const languageList = [
  { language: appLanguages.ENGLISH, icon: en, label: 'English' },
  { language: appLanguages.THAI, icon: th, label: 'แบบไทย' },
  { language: appLanguages.CANTON, icon: chm, label: '普通话' },
  { language: appLanguages.INDONESIAN, icon: id, label: 'bahasa Indonesia' },
];

export const LANGUAGES = filterLanguage();
