import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import config from 'config';

import LanguageToggle from '../components/LanguageToggle';
import { LANGUAGES } from '../constants';
import { LANG_COOKIE_NAME } from 'constants/index';
import * as authActions from 'modules/auth/actions';
import * as geolocationActions from 'modules/geolocation/actions';
import { getUser } from 'modules/auth/selectors';
import * as geolocationSelectors from 'modules/geolocation/selectors';
import * as selectors from '../selectors';
import * as actions from '../actions';

class LanguageToggleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.addedGtm = new Set();
  }

  componentDidMount() {
    const { getCountryByIpRequest } = this.props.geolocationActions;
    getCountryByIpRequest();
    this.props.authActions.setCxdForUserRequest();
    this.initGTM();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
    Cookies.set(LANG_COOKIE_NAME, lng, { path: '/', sameSite: 'none', secure: true });
    const {
      actions: { setCurrentLanguage },
    } = this.props;
    setCurrentLanguage(lng);
    this.props.authActions.setCxdForUserRequest();

    this.handleClose();
    this.initGTM();
  };

  addGtm(gtmId) {
    if (this.addedGtm.has(gtmId)) {
      return;
    }
    TagManager.initialize({ gtmId });
    this.addedGtm.add(gtmId);
  }

  initGTM() {
    if (this.props.i18n.language === 'th') {
      this.addGtm(config.GOOGLE_TAG_MANAGER.THAI_ID);
    }
    this.addGtm(config.GOOGLE_TAG_MANAGER.ID);
  }

  render() {
    const { currentLanguage, i18n } = this.props;
    const props = {
      anchorEl: this.state.anchorEl,
      lng: currentLanguage || i18n.language,
      handleClick: this.handleClick,
      changeLanguage: this.changeLanguage,
      handleClose: this.handleClose,
      menuItems: LANGUAGES,
    };
    //const isAU = this.props.ipCountry && this.props.ipCountry === 'AU';
    return <LanguageToggle {...props} />; //!this.props.ipCountry || isAU ? null :
  }
}

LanguageToggleContainer.propTypes = {
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  ipCountry: geolocationSelectors.getIpCountryCode(state),
  currentLanguage: selectors.getCurrentLanguage(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    geolocationActions: bindActionCreators(geolocationActions, dispatch),
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(LanguageToggleContainer));
