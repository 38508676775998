import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import TPModal from 'components/TP-UI/TPModal';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import TPCheckbox from 'components/TP-UI/TPCheckbox';
import TPGrid from 'components/TP-UI/TPGrid';
import FormError from 'modules/common/FormError';
import TPTypography from 'components/TP-UI/TPTypography';

const MfaSetupLaterModal = ({
  modalName,
  form,
  loading,
  submitFailed,
  error,
  handleSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('auth');
  const buttons = useMemo(
    () => [
      { label: t('common:buttons.confirm'), primary: true, type: 'submit', onClick: handleSubmit },
      { label: t('common:buttons.decline'), secondary: true, onClick: onCancel },
    ],
    [t, handleSubmit, onCancel],
  );

  return (
    <TPModal
      modalName={modalName}
      title={t('mfaSetup.skipModal.title')}
      buttons={buttons}
      loading={loading}
      onClose={onCancel}
      form={form}
      onSubmit={handleSubmit}>
      <TPGrid container spacing={3} direction="column">
        {submitFailed && error && (
          <TPGrid item>
            <FormError error={error} />
          </TPGrid>
        )}
        <TPGrid item>
          <TPTypography>{t('mfaSetup.skipModal.description')}</TPTypography>
        </TPGrid>
        <TPGrid item>
          <Field
            name="postponed"
            component={TPCheckbox}
            label={t('mfaSetup.skipModal.checkboxLabel')}
            reservedErrorSpace={false}
          />
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

MfaSetupLaterModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG })(MfaSetupLaterModal);
