import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';
import { setMetaDataDescription } from 'utils/setMetaDataDescription';
import config from 'config';

import classNames from 'classnames';
import Layout from '../../../../components/LayoutNew';
import TextInput from 'modules/common/TextInput';
import Button from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';
import styles from './styles';

import {
  email,
  required,
  password,
  checkNonLatin,
  stringLengthMax128,
} from 'utils/validation/fieldValidationRules';
import Checkbox from 'modules/common/Checkbox';
import { getTranslation } from 'utils/compositeTranslationHandler';
import SignUpCheckboxLabel from '../../../../components/SignUpCheckboxLabel';
import { languages } from 'constants/index';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import GoogleSignup from '../../../../components/GoogleSignup';
import { Routes } from 'constants/routeConstants';

const Form = ({
  classes,
  handleSubmit,
  onGoogleSignupSuccess,
  error = null,
  submitFailed,
  isPending,
}) => {
  const { t, i18n } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, IS_CLIM } = config;

  React.useEffect(() => {
    setMetaDataDescription(t('signUp.documentDescription', { company: CURRENT_HUB_LABEL }));
  }, [CURRENT_HUB_LABEL, t]);

  return (
    <Layout className={classes.spaceForFooter}>
      <DocumentTitle title={t('signUp.documentTitle', { company: CURRENT_HUB_LABEL })} />
      <div className={classes.formWrapper}>
        <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
          <img src={LOGO.blue} className={classes.logo} alt="black-logo" />
        </CustomLink>
        <div className={classes.container}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.welcomeBack}>{t('signUpWelcome')}</div>
            <div className={classes.welcomeBackDescription}>
              {t('signUpWelcomeDescription')}
            </div>{' '}
            <section className={classes.wrapper}>
              {error && submitFailed && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  &nbsp;
                  {getTranslation(t, error)}
                </span>
              )}
              <Field
                name="email"
                label={t('common:labels.email')}
                placeholder={t('common:labels.email')}
                type="text"
                component={TextInput}
                className={classes.textField}
                validate={[checkNonLatin, required, email]}
                focused
              />
              <Field
                label={t('common:labels.createPassword')}
                name="password"
                placeholder={t('common:labels.createPassword')}
                isConfidential
                tipText={t('tipText')}
                component={TextInput}
                className={classes.textField}
                validate={[required, password, stringLengthMax128]}
              />
              <Field
                name="confirmPassword"
                label={t('common:labels.confirmPassword')}
                placeholder={t('common:labels.confirmPassword')}
                isConfidential
                component={TextInput}
                className={classes.textField}
                validate={[required, password, stringLengthMax128]}
              />
              <section
                className={classNames(classes.conditions, {
                  [classes.section]: i18n.language !== languages.ENGLISH,
                })}>
                <Field name="conditions" component={Checkbox} label={<SignUpCheckboxLabel />} />
              </section>
              <Button
                fullWidth
                color="primary"
                size="large"
                label={t('common:buttons.signUp')}
                type="submit"
                className={classNames(classes.submitButton, {
                  [classes.climCustomButton]: IS_CLIM,
                })}
                isPending={isPending}
              />
              <section className={classes.customLinkWrapper}>
                <p className={classes.customLinkText}>
                  {t('alreadyHaveAccount')}{' '}
                  <CustomLink
                    className={classNames({ [classes.climCustomLink]: IS_CLIM })}
                    to={Routes.SIGNIN}>
                    {t('common:buttons.signIn')}
                  </CustomLink>
                </p>
              </section>
            </section>
          </form>
          <span className={classes.or}>{t('common:text.or')}</span>
          <GoogleSignup onSuccess={onGoogleSignupSuccess} />
        </div>
      </div>
    </Layout>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitFailed: PropTypes.bool.isRequired,
  onGoogleSignupSuccess: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    formError: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
    customLinkWrapper: PropTypes.string.isRequired,
    customLinkText: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(reduxForm(), withStyles(styles))(Form);
