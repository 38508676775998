import { useEffect, useCallback } from 'react';
import config from '../config';

export const useReCaptchaV3 = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${config.CAPTCHA.clientV3}`;
    script.async = false;
    script.defer = false;
    document.body.appendChild(script);

    return () => {
      const element = document.querySelector(`.grecaptcha-badge`);
      if (element) {
        element.style.display = 'none';
      }
      script.remove();
    };
  }, []);

  const getCaptchaV3 = useCallback(async () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        resolve('');
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(config.CAPTCHA.clientV3, { action: 'submit' })
          .then(function(token) {
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }, []);

  return {
    getCaptchaV3,
  };
};
