import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Divider from '../../../Divider';
import alert from 'static/alert.svg';

import styles from './styles';
import UserNotificationsContainer from 'modules/userNotifications/containers/notificationBell';
import AdminNotificationsContainer from 'modules/userNotifications/containers/notificationBellInternal';
import LanguageToggleContainer from 'modules/common/LanguageToggle/containers/LanguageToggleContainer';
import MenuDropDown from '../MenuDropdown';
import { useSelector } from 'react-redux';
import * as authSelectors from 'modules/auth/selectors';
import isMobile from 'utils/isMobile';

const hideMessageHandler = (warning, hideMessage, event) => {
  event.preventDefault();
  hideMessage(warning);
};

const Header = ({ classes, title, children, className, warning, isPublic, hideMessage }) => {
  const isAdminRole = useSelector(authSelectors.getIsAdminRole);
  const notificationCenter = isAdminRole ? (
    <AdminNotificationsContainer />
  ) : (
    <UserNotificationsContainer modalClassNames={classes.modal} isCloseButtonNeeded={false} />
  );
  return (
    !isPublic && (
      <div className={classes.header}>
        {warning && (
          <div className={classes.warning}>
            <div className={classes.warningWrapper}>
              <img src={alert} alt={'Alert Ico'} className={classes.ico} />
              {warning}
            </div>
            <IconButton
              className={classes.closeButton}
              onClick={hideMessageHandler.bind(null, warning, hideMessage)}
              aria-label="Close">
              <CloseIcon fontSize="inherit" width={20} height={20} />
            </IconButton>
          </div>
        )}
        <div className={classes.mainInfo}>
          <Typography className={classnames([classes.title, className])}>{title}</Typography>
          <div className={classes.circlesWrapper}>
            <div>{children}</div>
            {!isMobile() && (
              <>
                <LanguageToggleContainer />
                {notificationCenter}
                <Divider orientation="vertical" />
                <MenuDropDown />
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

Header.propTypes = {
  isPublic: PropTypes.bool,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  warning: PropTypes.string,
};

export default withStyles(styles)(Header);
