export const Routes = {
  SIGNIN: '/auth/sign-in',
  SIGNUP: '/auth/sign-up',
  HOME: '/',
  ACCOUNTS: '/accounts',
  MIGRATION_REQUESTS: '/migration-requests',
  MY_ACCOUNTS: '/accounts/list',
  PAYMENTS: '/payments',
  PAYMENT: '/payments/:token?',
  PAYMENTS_SUCCESS: '/payments?id=',
  CONFIRM_PAYMENT: '/payments/confirm-payment',
  PAY_ID_PAYMENT: '/payments/pay-id',
  PAYPAL_SIMULATE: '/payments/simulate-paypal',
  BANK_TRANSFER_PAYMENT: '/payments/bank-transfer',
  MT4_DOWNLOADS: '/mt4-downloads',
  MT4_DOWNLOADS_MOBILE: '/mt4-downloads-mobile',
  TRADE_IDEAS_RESEARCH: '/trade-ideas/research',
  TRADE_IDEAS_TECHNICAL_ANALYSIS: '/trade-ideas/ideas',
  TRADE_IDEAS_MARKET_NEWS: '/trade-ideas/market-news',
  TRADE_IDEAS_MARKET_REPORT: '/trade-ideas/market-report',
  TRADE_IDEAS_IMPACT_NEWS: '/trade-ideas/impact-news',
  TRADE_IDEAS_CORRELATING: '/trade-ideas/correlating',
  TRADE_IDEAS_PERFORMANCE_STATISTICS: '/trade-ideas/performance-statistics',
  TRADE_IDEAS_VOLATILITY_ANALYSIS: '/trade-ideas/volatile-analysis',
  LEARNINGS_NEWS_SENTIMENT: '/learnings/news-sentiment',
  LEARNINGS_NEWS_EDUCATION: '/learnings/education',
  MESSAGING_AND_ALERTS: '/messaging-and-alerts',
  PLATFORMS: '/platforms',
  PRIME_PLUS_ABOUT: '/plus/about',
  PRIME_PLUS_INVITATIONS: '/plus/invitations',
  PRIME_PLUS_TRADES: '/plus/trades',
  PRIME_PLUS_MY_FOLLOWERS: '/plus/my-followers',
  PRIME_PLUS_MASTER_ACCOUNTS: '/plus/master-accounts',
  PRIME_PLUS_FOLLOWER_JOIN: '/follower-join',
  PRIME_PLUS_MY_FOLLOWERS_WAITLIST: '/plus/waitlist',
  MY_PERFORMANCE: '/my-performance/:id',
  USER_PERFORMANCE: '/shared-performance/:id',
  ECONOMIC_CALENDAR: '/economic-calendar',
  MIGRATE_MY_ACCOUNT: '/migrate-my-account',
  UPGRADE_TO_PRO: '/upgrade-to-pro',
  AUTH: '/auth',
  AUTH_MFA_INPUT: '/auth/mfa-input',
  AUTH_MFA_SETUP: '/auth/mfa',
  AUTH_MFA_REQUIRED: '/auth/mfa-required',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_SIGN_IN_INTERNAL: '/auth/sign-in/internal',
  AUTH_PARTNERS: '/auth/partners',
  AUTH_PARTNERS_SIGNIN: '/auth/partners/sign-in',
  AUTH_FORCE_PASSWORD_CHANGE: '/auth/force-password-change',
  AUTH_RESET_PASSWORD: '/auth/reset-password/:token?',
  AUTH_OUTDATED_PASSWORD: '/auth/outdated-password',
  AUTH_MIGRATION: '/auth/migrate/:token?',
  AUTH_EMAIL_VERIFICATION: '/auth/email-verification',
  PROFILE: '/profile',
  PROFILE_VERIFICATION: '/profile/verification',
  UPGRADE_TO_PRO_V_2: '/upgrade-to-pro-v2',
  USER_MANAGEMENT: '/user-management',
  USER_MANAGEMENT_DETAILS: '/user-management/user',
  USER_MANAGEMENT_DETAILS_ID: '/user-management/user/:id',
  TICKET_HISTORY: '/tickets/history',
  TICKET_HISTORY_ID: '/tickets/history/:id',
  VERIFICATION_MANAGEMENT: '/verification-management',
  CONTACT_US: '/contact-us',
  VERIFICATION_MANAGEMENT_DETAILS: '/verification-management/request',
  VERIFICATION_MANAGEMENT_DETAILS_ID: '/verification-management/request/:id',
  VERIFICATION_MANAGEMENT_REQUESTS: '/verification-management/client-requests',
  VERIFICATION_MANAGEMENT_USERS_REQUESTS_TAB: '/client-requests/:pageNumber?',
  VERIFICATION_MANAGEMENT_ACCOUNTS_REQUESTS_TAB: '/account-requests/:pageNumber?',
  VERIFICATION_MANAGEMENT_FOLLOWUPS_REQUESTS_TAB: '/followup-requests/:pageNumber?',
  VERIFICATION_MANAGEMENT_EXPERIENCE_UPLOADS_TAB: '/experience-uploads/:pageNumber?',
  VERIFICATION_MANAGEMENT_KYC_REQUESTS: '/kys-requests/:pageNumber?',
  VERIFICATION_MANAGEMENT_KYC_PENDING_REQUESTS: '/kyc-pending-requests/:pageNumber?',
  PAYMENT_MANAGEMENT: '/payment-management',
  PAYMENT_MANAGEMENT_DETAILS: '/payment-management/request',
  PAYMENT_MANAGEMENT_DETAILS_ID: '/payment-management/request/:id',
  EMAIL_MANAGEMENT: '/email-management',
  TRADING_CALCULATORS: '/trading-calculators',
  PUBLIC_CALCULATORS: '/public-calculators',
  TECHNICAL_INSIGHT: '/insight/technical-insight',
  PUBLIC_ECONOMIC_CALENDAR: '/public-economic-calendar',
  PAGE_WARNING: '/page-warning-configuration',
  RISK_GROUP: '/risk-group',
  PAYMENT_QUEUE: '/payment-queue/:pageNumber?',
  NOTIFICATIONS: '/notifications',
  REDUCE_FEES: '/reduce-fees',
  MARKET_BUZZ: '/market-buzz',
  MARKET_BUZZ_DASHBOARD: '/market-buzz/dashboard',
  MARKET_BUZZ_DASHBOARD_ID: '/market-buzz/dashboard/:entityId',
  MY_FEEDS: '/news/my-feeds',
  MY_FEEDS_WITH_ID: '/news/my-feeds/:id',
  SENTIMENT: '/news/sentiment',
  ANALYST_VIEWS: '/insight/analyst',
  ANALYST_VIEWS_WITH_ID: '/insight/analyst/:id',
  NEWS_SEARCH: '/news/search',
  SECURITY: '/security',
  OPERATIONS: '/operations',
  SETTINGS: '/settings',
  ERROR_HANDLER_EXAMPLE: '/error-handler-example',
  MFA_VERIFICATION: '/auth/mfa-input',
  MFA_REQUIRED: '/auth/mfa-required',
  DASHBOARD: '/dashboard',
  CELLXPERT_RESET_PASSWORD: '/ib-reset-password/:token',
  LEAD_JOIN: '/user/join/:token',
  VPS: '/vps',
  CUSTOM_FIELDS: '/custom-fields',
  CUSTOM_FIELDS_ANSWERS: '/custom-fields-answers',
  IM_USER_MESSAGE: '/user-management/im',
  MAINTENANCE: '/maintenance',
  CRYPTO_WAITLIST: '/crypto-waitlist',
  DAY1_EXCHANGE: '/day1-exchange',
};

export const RoutesWithParams = {
  PAYMENT_MANAGEMENT_DETAILS: '/payment-management/request/:id',
  USER_MANAGEMENT_DETAILS: '/user-management/user/:id',
  VERIFICATION_MANAGEMENT_DETAILS: '/verification-management/request/:id',
  MARKET_BUZZ_DASHBOARD: '/market-buzz/dashboard/:entityId',
  TICKET_HISTORY: '/tickets/history/:id',
};
